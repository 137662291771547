// ** Redux Imports
import rootReducer from "./rootReducer"
import { configureStore, compose } from "@reduxjs/toolkit"
import { apiSlice } from "../features/api/apiSlice"
import { loadingBarMiddleware } from "react-redux-loading-bar"
import { setupListeners } from "@reduxjs/toolkit/dist/query/react"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
      composeEnhancers
    })
      .concat(
        loadingBarMiddleware({
          promiseTypeSuffixes: ["pending", "fulfilled", "rejected"]
        })
      )
      .concat(apiSlice.middleware)
  },
  devTools: process.env.NODE_ENV !== "production"
})

setupListeners(store.dispatch)

export { store }
