import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: JSON.parse(localStorage.getItem("user")) || null,
    accessToken: localStorage.getItem("accessToken") || null
  },
  reducers: {
    updateLoggedInUser: (state, action) => {
      state.user = { ...state.user, ...action.payload }
      window.localStorage.setItem("user", JSON.stringify(state.user))
    },
    updateLoggedInAvatar: (state, action) => {
      state.user = { ...state.user, avatar_url: action.payload }
      window.localStorage.setItem("user", JSON.stringify(state.user))
    },
    setCredential: (state, action) => {
      const { user, accessToken } = action.payload
      window.localStorage.setItem("accessToken", accessToken)
      window.localStorage.setItem("user", JSON.stringify(user))
      window.localStorage.setItem("abilitys", JSON.stringify(user?.abilitys))
      state.user = user
      state.accessToken = accessToken
    },
    logout: (state) => {
      window.localStorage.removeItem("accessToken")
      window.localStorage.removeItem("user")
      window.localStorage.removeItem("abilitys")
      state.user = null
      state.accessToken = null
    }
  }
})

export const {
  setCredential,
  updateLoggedInUser,
  updateLoggedInAvatar,
  logout
} = authSlice.actions

export const selectCurrentUser = (state) => state.auth.user
export const selectCurrenToken = (state) => state.auth.accessToken

export default authSlice.reducer
