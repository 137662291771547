// ** Initial user ability
let initialAbility = []

const localAbiliys = localStorage.getItem("abilitys")

if (localAbiliys && localAbiliys !== undefined) {
  initialAbility = JSON.parse(localAbiliys) || []
}

export { initialAbility }

export const _ = undefined
