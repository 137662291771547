// ** I18n Imports
import i18n from "i18next"
import Backend from "i18next-xhr-backend"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import themeConfig from "@configs/themeConfig"

i18n

  // Enables the i18next backend
  .use(Backend)

  // Enable automatic language detection
  .use(LanguageDetector)

  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    fallbackLng: themeConfig.layout.locale.defaultLocale,
    ns: ["common"],
    defaultNS: "common",
    backend: {
      /* translation file path */
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`
    },
    fallbackLng: themeConfig.layout.locale.defaultLocale,
    detection: {
      order: ["localStorage", "path", "subdomain"],
      caches: ["localStorage"]
    },
    debug: false,
    keySeparator: false,
    react: {
      useSuspense: false
    },
    //nonExplicitSupportedLngs: false,
    load: "currentOnly",
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
