/* eslint-disable multiline-ternary */
// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const getAllData = createAsyncThunk("appUsers/getAllData", async () => {
  const response = await axios.get("/api/users/list/all-data")
  return response.data
})

export const getData = createAsyncThunk("appUsers/getData", async (params) => {
  let req = `/user?count=${params.perPage}`
  req += params.user_entity ? `&filter[user_entity]=${params.user_entity}` : ""
  req += params.status ? `&filter[status]=${params.status}` : ""
  req += params.user_type ? `&filter[user_type]=${params.user_type}` : ""
  req += params.q ? `&filter[name]=${params.q}` : ""
  req += params.sortColumn
    ? `&sort=${params.sort === "desc" ? "-" : ""}${params.sortColumn}`
    : ""

  console.log("params: ", params)

  try {
    const response = await axios.get(req, params)
    const { data } = response
    return {
      params,
      data: data.data,
      totalPages: data.total,
      perPage: data.per_page
    }
  } catch (err) {
    console.log("in thunk catch: ", err)
    return err
  }
})

export const getUser = createAsyncThunk("appUsers/getUser", async (id) => {
  try {
    const response = await axios.get(`/user/${id}`)
    const { data } = response
    return data
  } catch (err) {
    return err
  }
})

// export const addUser = createAsyncThunk(
//   "appUsers/addUser",
//   async (user, { dispatch, getState }) => {
//     try {
//       await axios.post("/apps/users/add-user", user)
//       await dispatch(getData(getState().users.params))
//       await dispatch(getAllData())
//       return user
//     } catch (err) {
//       console.log("err:", err)
//       return err
//     }
//   }
// )

export const addUser = createAsyncThunk("appUsers/addUser", async (user) => {
  try {
    const res = await axios.post("/user", user)
    await dispatch(getData(getState().users.params))
    const { data } = res
    return data
  } catch (err) {
    console.log("err:", err)
    return err
  }
})

export const deleteUser = createAsyncThunk(
  "appUsers/deleteUser",
  async (id, { dispatch, getState }) => {
    await axios.delete("/apps/users/delete", { id })
    await dispatch(getData(getState().users.params))
    await dispatch(getAllData())
    return id
  }
)

export const appUsersSlice = createSlice({
  name: "appUsers",
  initialState: {
    data: [],
    loadingData: false,
    total: 1,
    error: null,
    params: {},
    allData: [],
    perPage: 15,
    selectedUser: null,
    fetchingUser: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.pending, (state) => {
        state.loadingData = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        const { payload } = action
        state.data = payload.data
        state.params = payload.params
        state.perPage = payload.perPage
        state.total = payload.totalPages
        state.loadingData = false
      })
      .addCase(getUser.pending, (state, action) => {
        console.log("action.payload: ", action.payload)
        state.fetchingUser = true
      })
      .addCase(getUser.fulfilled, (state, action) => {
        console.log("action.payload: ", action.payload)
        state.selectedUser = action.payload
        state.fetchingUser = false
      })
  }
})

export const getUserList = (state) => state.users.data

export default appUsersSlice.reducer
