// ** Reducers Imports
import { loadingBarReducer } from "react-redux-loading-bar"

import navbar from "./navbar"
import layout from "./layout"

import todo from "@src/views/apps/todo/store"
import chat from "@src/views/apps/chat/store"
import users from "@src/views/apps/user/store"
import email from "@src/views/apps/email/store"
import invoice from "@src/views/apps/invoice/store"
import calendar from "@src/views/apps/calendar/store"
import ecommerce from "@src/views/apps/ecommerce/store"
import dataTables from "@src/views/tables/data-tables/store"
import permissions from "@src/views/apps/roles-permissions/store"

import { apiSlice } from "@features/api/apiSlice"
import auth from "@features/auth/authSlice"

const rootReducer = {
  loadingBar: loadingBarReducer,
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  [apiSlice.reducerPath]: apiSlice.reducer
}

export default rootReducer
