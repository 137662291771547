/* eslint-disable multiline-ternary */

// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig"

const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem("menuCollapsed")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

const initialDirection = () => {
  const item = window.localStorage.getItem("direction")
  //** Parse stored json or if none return initialValue
  if (item) {
    return JSON.parse(item)
  } else {
    return themeConfig.layout.locale?.defaultLocale === "ar"
  }
  //return item ? JSON.parse(item) : themeConfig.layout.isRTL
}

const initialLayout = () => {
  const item = window.localStorage.getItem("layout")
  //** Parse stored json or if none return initialValue
  return item ? item : themeConfig.layout.type
}

const initialSkin = () => {
  const item = window.localStorage.getItem("skin")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.skin
}

const initialWidth = () => {
  const item = window.localStorage.getItem("contentWidth")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.contentWidth
}

const initialNavColor = () => {
  const item = window.localStorage.getItem("navbarColor")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.navbar.backgroundColor
}

const initialNavType = () => {
  const item = window.localStorage.getItem("navbarType")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.navbar.type
}

const initialLocale = () => {
  const item = window.localStorage.getItem("i18nextLng")
  //** Parse stored json or if none return initialValue
  return item && (item === "ar" || item === "en")
    ? item
    : themeConfig.layout.locale?.defaultLocale
}

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    skin: initialSkin(),
    isRTL: initialDirection(),
    locale: initialLocale(),
    layout: initialLayout(),
    lastLayout: initialLayout(),
    menuCollapsed: initialMenuCollapsed(),
    footerType: themeConfig.layout.footer.type,
    navbarType: initialNavType(),
    menuHidden: themeConfig.layout.menu.isHidden,
    contentWidth: initialWidth(),
    routerTransition: themeConfig.layout.routerTransition,
    navbarColor: initialNavColor()
  },
  reducers: {
    handleRTL: (state, action) => {
      state.isRTL = action.payload
      window.localStorage.setItem("direction", JSON.stringify(action.payload))
    },
    handleSkin: (state, action) => {
      state.skin = action.payload
      window.localStorage.setItem("skin", JSON.stringify(action.payload))
    },
    handleLocale: (state, action) => {
      console.log(action)
      const { code, dir } = action?.payload
      state.locale = code
      state.isRTL = dir === "rtl"

      window.localStorage.setItem("i18nextLng", code)
      window.localStorage.setItem("direction", JSON.stringify(dir === "rtl"))
    },
    handleLayout: (state, action) => {
      const { payload } = action
      window.localStorage.setItem("layout", payload)
      state.layout = payload
    },
    handleFooterType: (state, action) => {
      state.footerType = action.payload
    },
    handleNavbarType: (state, action) => {
      const { payload } = action
      window.localStorage.setItem("navbarType", JSON.stringify(payload))
      state.navbarType = payload
    },
    handleMenuHidden: (state, action) => {
      state.menuHidden = action.payload
    },
    handleLastLayout: (state, action) => {
      state.lastLayout = action.payload
    },
    handleNavbarColor: (state, action) => {
      state.navbarColor = action.payload
      window.localStorage.setItem("navbarColor", JSON.stringify(action.payload))
    },
    handleContentWidth: (state, action) => {
      state.contentWidth = action.payload
      window.localStorage.setItem(
        "contentWidth",
        JSON.stringify(action.payload)
      )
    },
    handleMenuCollapsed: (state, action) => {
      state.menuCollapsed = action.payload
      window.localStorage.setItem(
        "menuCollapsed",
        JSON.stringify(action.payload)
      )
    },
    handleRouterTransition: (state, action) => {
      state.routerTransition = action.payload
    }
  }
})

export const {
  handleRTL,
  handleSkin,
  handleLocale,
  handleLayout,
  handleLastLayout,
  handleMenuHidden,
  handleNavbarType,
  handleFooterType,
  handleNavbarColor,
  handleContentWidth,
  handleMenuCollapsed,
  handleRouterTransition
} = layoutSlice.actions

export default layoutSlice.reducer
