// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: "Vass",
    apiUrl:
      process.env === "production"
        ? "https://api.spslink.net/api/v1"
        : "https://api.spslink.net/api/v1",
    appLogoImage: require("@src/assets/images/logo/logo.svg").default,
    defaultRoute: "/dashboard/ecommerce",
    //** default login design to navigate to, if user not logged in
    defaultLoginRoute: "/auth/login" //** */ /auth/login-basic, /auth/login
  },
  pagination: [
    {
      label: 5,
      value: 5
    },
    {
      label: 10,
      value: 10
    },
    {
      label: 15,
      value: 15
    },
    {
      label: 20,
      value: 20
    },
    {
      label: 30,
      value: 30
    },
    {
      label: 40,
      value: 40
    },
    {
      label: 50,
      value: 50
    }
  ],
  layout: {
    isRTL: false, // true, false
    skin: "light", // light, dark, bordered, semi-dark
    locale: {
      defaultLocale: "ar", // "ar", "en"
      supportedLocales: [
        {
          code: "en",
          name: "English",
          country_code: "us",
          dir: "ltr"
        },
        {
          code: "ar",
          name: "Arabic",
          country_code: "sa",
          dir: "rtl"
        }
      ]
    },
    routerTransition: "fadeIn", // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: "vertical", // vertical, horizontal
    contentWidth: "boxed", // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "floating", // static , sticky , floating, hidden
      backgroundColor: "white" // BS color options [primary, success, etc]
    },
    footer: {
      type: "hidden" // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button,
    toastPosition: "top-center", // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right,
    toastDuration: 4000
  }
}

export default themeConfig
